import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import moment from "moment";
import 'moment/locale/hu';

import Layout from "../components/layouts/Layout";
import EventCard from "../components/cards/EventCard";
import Seo from "../components/main/SEO";

import Col from "../components/styled-components/wrappers/Col";
import H2 from "../components/styled-components/typography/H2";
import LinkButton from "../components/styled-components/button/LinkButton";
import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import Divider from "../components/styled-components/divider/Divider";

const Events = () => {

    const data = useStaticQuery(graphql`
    {
      activeEvents: allStrapiBlackowlEvents(
        sort: {fields: date, order: ASC}
        filter: {blackowl_meetup: {isActive: {eq: true}}}
      ) {
        active: nodes {
          date
          id
          title
          slug
          Hero_Image {
            url
          }
          speaker {
            avatar {
              url
            }
            slug
            name
          }
          additional_speakers {
            avatar {
              url
            }
            slug
            name
          }
        }
      }
      prevEvents: allStrapiBlackowlEvents(
        sort: {fields: date, order: DESC}
        filter: {blackowl_meetup: {isActive: {eq: false}}}
      ) {
        prev: nodes {
          date
          id
          title
          slug
          Hero_Image {
            url
          }
          speaker {
            avatar {
              url
            }
            slug
            name
          }
          additional_speakers {
            avatar {
              url
            }
            slug
            name
          }
        }
      }
    }
  `)

    const {activeEvents: {active}} = data;
    const {prevEvents: {prev}} = data;

    return (
        <Layout title={"Előadások"}>
            <Seo title={"Balasys Blackowl - Előadások"} description={"A Balasys Blackowl előadások oldala."}
                 keywords={"balasys, blackowl, előadások"} url={"/events/"}/>
            <SectionLayoutGrid>
                <Col full marginBottom="small">
                    <H2 medium>Aktuális előadások</H2>
                    <Divider fullOrange/>
                </Col>
                {active.length !== 0 && active.map((item) => (
                    <Col key={item.id} events marginBottom="small">
                        <EventCard
                            hero={item.Hero_Image.url}
                            time={moment(item.date).locale("hu").format("LLL")}
                            eventTitle={item.title}
                            eventSlug={item.slug}
                            speakerAvatar={item.speaker.avatar.url}
                            speakerSlug={item.speaker.slug}
                            speakerName={item.speaker.name}
                            additional_speakers={item.additional_speakers}
                        />
                    </Col>
                ))}
                {active.length === 0 &&
                <Col events marginBottom="small">
                    <H2 small>Hamarosan...</H2>
                </Col>
                }
                {active.length !== 0 &&
                <Col full center marginBottom="medium">
                    <LinkButton to={"/#register"}>Regisztráció</LinkButton>
                </Col>
                }
                <Col full marginBottom="medium">
                    <Divider full/>
                </Col>
                <Col full marginBottom="small">
                    <H2 medium>Korábbi előadások</H2>
                    <Divider fullOrange/>
                </Col>
                {prev.map((ev) => {
                        return (
                            <Col key={ev.id} events marginBottom="small">
                                <EventCard
                                    hero={ev.Hero_Image.url}
                                    time={moment(ev.date).locale("hu").format("LLL")}
                                    eventTitle={ev.title}
                                    eventSlug={ev.slug}
                                    speakerAvatar={ev.speaker.avatar.url}
                                    speakerSlug={ev.speaker.slug}
                                    speakerName={ev.speaker.name}
                                    additional_speakers={ev.additional_speakers}
                                />
                            </Col>
                        )
                    }
                )}
            </SectionLayoutGrid>
        </Layout>
    );
};

export default Events;